/* index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply font-sans;
  }
/* 自定义类以禁用 -tw-ring-shadow */
.disable-ring-shadow:focus {
    box-shadow: none !important;
}
